<template>
  <div>
    <PageHeader :title="$t('backoffice.tariff')" :items="items" />
    <b-card class="mb-3 mt-3">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h4 class="text-center">Filtrar</h4>
            <b-row>
              <b-col md="4" class="d-flex justify-content">
                <!-- Esta coluna vai ocupar 4 de 12 colunas no layout médio -->
                <b-form-group
                  label="Distribuidora"
                  label-for="distribuidoraSelect"
                >
                  <b-select
                    id="distribuidoraSelect"
                    v-model="selectedDistribuidora"
                  >
                    <option value="">Todas</option>
                    <option
                      v-for="distribuidora in uniqueDistribuidoras"
                      :key="distribuidora.id"
                      :value="distribuidora.nomeDistribuidora"
                    >
                      {{ distribuidora.nomeDistribuidora }}
                    </option>
                  </b-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h4 class="text-center">Tarifas Resolução</h4>
            <b-table striped :items="filteredTarifas" :fields="tableFields">
              <template v-slot:cell(id)="row">
                <a class="underline-hover" @click="redirectPage(row.item)">{{
                  row.value
                }}</a>
              </template>
              <template v-slot:cell(dataResolucao)="dataResolucao">
                {{ formatDate(dataResolucao.item.dataResolucao) }}
              </template>
              <template v-slot:cell(vigenciaIni)="vigenciaIni">
                {{ formatDate(vigenciaIni.item.vigenciaIni) }}
              </template>
              <template v-slot:cell(vigenciaFim)="vigenciaFim">
                {{ formatDate(vigenciaFim.item.vigenciaFim) }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import { listAll } from './tarifaResolucao.service';
import { i18n } from '../../main';
import PageHeader from '@/components/page-header.vue';

export default {
  components: { PageHeader },
  data() {
    return {
      title: i18n.tc('backoffice.tariff'),
      tarifas: [],
      selectedDistribuidora: ''
    };
  },
  computed: {
    uniqueDistribuidoras() {
      const distribuidoras = {};
      this.tarifas.forEach((tarifa) => {
        distribuidoras[tarifa.distribuidora.id] = tarifa.distribuidora;
      });
      return Object.values(distribuidoras);
    },
    filteredTarifas() {
      if (this.selectedDistribuidora) {
        return this.tarifas.filter(
          (tarifa) =>
            tarifa.distribuidora.nomeDistribuidora ===
            this.selectedDistribuidora
        );
      } else {
        return this.tarifas;
      }
    },
    tableFields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'distribuidora.nomeDistribuidora', label: 'Distribuidora' },
        { key: 'numeroResolucao', label: 'Número Resolução' },
        { key: 'dataResolucaoUI', label: 'Data' },
        { key: 'vigenciaIni', label: 'Ini. Vigência' },
        { key: 'vigenciaFim', label: 'Fim Vigência' }
      ];
    },
    items: () => [
      {
        text: 'BackOffice',
        href: '/'
      },
      {
        text: i18n.tc('backoffice.tariff'),
        active: true
      }
    ]
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('pt-BR');
    },
    async buscarTarifas() {
      try {
        const response = await listAll(); // Chamar a função de serviço para buscar os dados
        if (response && response.length > 0 && response[0].content) {
          this.tarifas = response[0].content; // Atribuir os dados de 'content' a this.tarifas

          // Ordenar as distribuidoras em ordem alfabética
          this.uniqueDistribuidoras.sort((a, b) => {
            const nomeA = a.nomeDistribuidora.toUpperCase();
            const nomeB = b.nomeDistribuidora.toUpperCase();
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }
            return 0;
          });
        } else {
          console.warn('Resposta inválida do serviço:', response);
          // Lidar com uma resposta inválida, por exemplo, mostrando uma mensagem de erro
        }
      } catch (error) {
        console.error('Erro ao buscar as tarifas:', error);
        // Lidar com erros, por exemplo, exibir uma mensagem de erro na tela
      }
    },
    redirectPage(resolucao) {
      //console.log(resolucao);

      resolucao.dataResolucaoUI = this.formatarDataParaRedirect(
        resolucao.dataResolucaoUI
      );

      this.$router.push({
        path: '/list-tarifa-resolucao-item',
        query: { data: JSON.stringify(resolucao) }
      });
    },
    formatarDataParaRedirect(data) {
      const partesData = data.split('/');
      if (partesData.length === 3) {
        const ano = partesData[2];
        const mes = partesData[1];
        const dia = partesData[0];
        return `${ano}-${mes}-${dia}`;
      }
      return data; // Se a data estiver em um formato inesperado, retorne-a sem alterações
    }
  },
  mounted() {
    this.buscarTarifas();
  }
};
</script>
